import PrimaryButton from "../../../../components/Button2/PrimaryButton";
import SecondaryButton from "../../../../components/Button2/SecondaryButton";
import Card from "../../../../components/Card/Card";
import Overlay from "../../../../components/Overlay/Overlay";
import styles from "./DraftDispenseDateDialog.module.css";
import { useForm } from "react-hook-form";
import { validateDueDate } from "../../../../utils";

function getLocalizedTodayString() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export default function DraftDispenseDateDialog(props: {
  initialDate?: Date;
  isAdding: boolean;
  onSubmit: (date: string) => void;
  onCancel: () => void;
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ dispenseDate: string }>({
    defaultValues: {
      dispenseDate: props.initialDate?.toISOString().split("T")[0],
    },
  });

  const _onSubmit = (data: { dispenseDate: string }) => {
    props.onSubmit(data.dispenseDate);
  };

  return (
    <Overlay>
      <Card className={styles.editDispenseDateCard}>
        <form onSubmit={handleSubmit(_onSubmit)}>
          <h3>{props.isAdding ? "Add" : "Edit"} dispense date</h3>
          <p className={styles.editDispenseDateDescription}>
            Please note that MediCircle does not ship prescriptions on weekends.
          </p>
          <div className={styles.inputContainer}>
            <input
              autoFocus
              min={getLocalizedTodayString()}
              type="date"
              {...register("dispenseDate", {
                required: "Dispense date is required",
                validate: validateDueDate,
              })}
              className={styles.dispenseDateInput}
              id="dispenseDate"
              data-testid="DispenseListItem__edit_dispense_input"
            />
            {errors.dispenseDate && (
              <p className={`${styles.errorMsg} error error-small`}>
                {errors.dispenseDate.message}
              </p>
            )}
          </div>
          <div className={styles.editDispenseDateActions}>
            <SecondaryButton onClick={props.onCancel} type="button">
              Cancel
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              testId="DispenseListItem__confirm_update"
            >
              {props.isAdding ? "Submit" : "Update"}
            </PrimaryButton>
          </div>
        </form>
      </Card>
    </Overlay>
  );
}
