export interface DraftDispenseJson {
  id: string;
  dispense_date: string;
}

export class DraftDispense {
  constructor(public pk: string, public dispenseDate: Date) {}

  static fromJson(data: DraftDispenseJson) {
    return new DraftDispense(data.id, new Date(data.dispense_date));
  }
}

export interface DraftPrescriptionJson {
  uuid: string;
  quantity: number;
  total_remaining_fills: number;
  patient_name: string;
  patient_address: string;
  medication_name: string;
  medication_strength: string;
  medication_formulation: string;
  patient_id: string;
  medication_id: string;
  draft_dispenses: DraftDispenseJson[];
  fees_per_fill: number;
  initial_fill_date: string;
  days_supply: number;
}

export class DraftPrescription {
  constructor(
    public uuid: string,
    public quantity: number,
    public totalRemainingFills: number,
    public patientName: string,
    public patientAddress: string,
    public medicationName: string,
    public medicationStrength: string,
    public medicationFormulation: string,
    public patientId: string,
    public medicationId: string,
    public dispenses: DraftDispense[],
    public feesPerFill: number,
    public initialFillDate: Date,
    public daysSupply: number
  ) {}

  static fromJson(data: DraftPrescriptionJson) {
    return new DraftPrescription(
      data.uuid,
      data.quantity,
      data.total_remaining_fills,
      data.patient_name,
      data.patient_address,
      data.medication_name,
      data.medication_strength,
      data.medication_formulation,
      data.patient_id,
      data.medication_id,
      data.draft_dispenses.map(DraftDispense.fromJson),
      data.fees_per_fill,
      new Date(data.initial_fill_date),
      data.days_supply
    );
  }
}
