import { useEffect, useState } from "react";
import DashboardPageBody from "../../../components/DashboardPageBody/DashboardPageBody";
import { ApiStatus } from "../../../types/api_status";
import { useNavigate, useParams } from "react-router";
import styles from "./styles.module.css";
import { getPrettyDate, isInternalPath } from "../../../utils";
import LinkIcon from "../../../assets/Link.svg";
import Trash from "../../../assets/Trash.svg";
import Edit from "../../../assets/Edit.svg";
import ConsentFormLinkPopup from "../../../components/ConsentFormLinkPopup/ConsentFormLinkPopup";

import { useGlobalOverlay } from "../../../providers/GlobalOverlayProvider";
import PatientDetailCard from "../components/PatientDetailCard";
import { deletePatient, getPatient, updatePatientStatus } from "../api";
import { Patient, PatientStatus } from "../types";
import { Link, useSearchParams } from "react-router-dom";
import PrimaryButton from "../../../components/Button2/PrimaryButton";
import SecondaryButton from "../../../components/Button2/SecondaryButton";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { toast } from "react-toastify";
import { createPortal } from "react-dom";
import Overlay from "../../../components/Overlay/Overlay";
import Card from "../../../components/Card/Card";
import { useForm } from "react-hook-form";

interface PatientDetailFetchState {
  status: ApiStatus;
  patient: Patient | null;
}

export default function PatientDetail() {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { setGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();

  const portalContainer =
    document.getElementById("portal-root") || document.body;

  const [searchParams] = useSearchParams();
  let backPath = searchParams.get("back");
  if (backPath && !isInternalPath(backPath)) {
    backPath = null;
  }

  const {
    reset,
    register,

    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<{ status: PatientStatus; statusDescription?: string }>({
    mode: "onChange",
  });

  const [patientDetailFetchState, setPatientDetailFetchState] =
    useState<PatientDetailFetchState>({
      status: ApiStatus.LOADING,
      patient: null,
    });

  const [deletePatientState, setDeletePatientState] = useState<ApiStatus>(
    ApiStatus.INITIAL
  );

  const _handleStatusSubmit = (data: {
    status: PatientStatus;
    statusDescription?: string;
  }) => {
    if (!patient) {
      return;
    }

    return updatePatientStatus(patient.id, data.status, data.statusDescription)
      .then((patient) => {
        toast.success("Successfully updated patient outreach status");
        setShowStatusEditor(false);
        setPatientDetailFetchState((prev) => ({ ...prev, patient }));
      })
      .catch(() => {
        toast.error("Unable to update patient outreach status");
      });
  };

  const [showStatusEditor, setShowStatusEditor] = useState(false);

  const _deletePatient = () => {
    if (!patient) {
      return;
    }
    setDeletePatientState(ApiStatus.LOADING);
    deletePatient(patient.id)
      .then(() => {
        setDeletePatientState(ApiStatus.SUCCESS);
        toast.success("Successfully deleted patient");
        navigate("/patients");
      })
      .catch((e) => {
        setDeletePatientState(ApiStatus.FAILURE);
        hideGlobalOverlay();
        if (e.response.data) {
          toast.error(e.response.data[0]);
        } else {
          toast.error("Error deleting patient");
        }
      });
  };

  useEffect(() => {
    if (patientId === undefined) {
      setPatientDetailFetchState((prev) => ({
        ...prev,
        status: ApiStatus.FAILURE,
      }));
    } else {
      getPatient(patientId)
        .then((result) => {
          setPatientDetailFetchState((prev) => ({
            ...prev,
            patient: result,
          }));
        })
        .catch((e) => {
          setPatientDetailFetchState((prev) => ({
            ...prev,
            status: ApiStatus.FAILURE,
          }));
        });
    }
  }, [patientId]);

  const patient = patientDetailFetchState.patient;

  useEffect(() => {
    if (patient) {
      reset({
        status: patient.status,
        statusDescription: patient.statusDescription,
      });
    }
  }, [patient, reset]);

  return (
    <>
      <DashboardPageBody
        backLink={backPath ?? "/patients"}
        title="Patient"
        headerSuffix={
          patientDetailFetchState.patient && (
            <div className={styles.actions}>
              <SecondaryButton
                onClick={() => {
                  if (!patientId) {
                    return;
                  }
                  setGlobalOverlay(
                    <ConsentFormLinkPopup
                      patientId={patientId}
                      onDone={hideGlobalOverlay}
                    ></ConsentFormLinkPopup>
                  );
                }}
              >
                <img
                  src={LinkIcon}
                  title="Get patient form link"
                  alt="Patient link"
                  className={styles.linkIcon}
                ></img>
                Consent form link
              </SecondaryButton>
              <SecondaryButton
                onClick={() => {
                  if (!patient) {
                    return;
                  }
                  navigate(`/patient/${patient.id}/edit`);
                }}
                testId="PatientDetail__edit"
              >
                <img src={Edit} alt="Trash" className={styles.linkIcon} />
                Edit
              </SecondaryButton>
              <SecondaryButton
                onClick={() => {
                  setGlobalOverlay(
                    <ConfirmationDialog
                      title={"Are you sure you want to delete this patient?"}
                      isDestructive={true}
                      rightLabel="Delete"
                      loading={deletePatientState === ApiStatus.LOADING}
                      onConfirm={_deletePatient}
                      onCancel={hideGlobalOverlay}
                    ></ConfirmationDialog>
                  );
                }}
              >
                <img src={Trash} alt="Trash" className={styles.linkIcon} />
                Delete
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  navigate(`/patients/${patientId}/prescriptions/create`);
                }}
                testId="PatientDetail__add_prescription"
              >
                <img
                  alt="Plus"
                  className={styles.plusIcon}
                  src={require(`../../../assets/PlusWhite.svg`).default}
                ></img>
                Add prescription
              </PrimaryButton>
            </div>
          )
        }
      >
        <div>
          <PatientDetailCard
            status={patientDetailFetchState.status}
            patient={patientDetailFetchState.patient}
            onClickStatus={() => setShowStatusEditor(true)}
            allowChangeStatus={true}
          ></PatientDetailCard>
          {patient && (
            <div className={styles.prescriptionsSection}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Prescription</th>
                    <th>Quantity</th>
                    <th>Received</th>
                    <th>Consent forms</th>
                    <th>Created on</th>
                  </tr>
                </thead>
                <tbody>
                  {patient.prescriptions.length === 0 ? (
                    <tr>
                      <td colSpan={6} className={styles.emptyState}>
                        No prescriptions yet
                      </td>
                    </tr>
                  ) : (
                    patient.prescriptions.map((prescription) => (
                      <tr key={prescription.id}>
                        <td>
                          <Link
                            to={`/patients/${patient.id}/prescriptions/${prescription.id}`}
                            className={styles.prescriptionLink}
                            data-testid={`PatientDetail__prescription_link__${prescription.id}`}
                          >
                            {prescription.medicationName}{" "}
                            {prescription.medicationStrength}
                          </Link>
                        </td>
                        <td>
                          {prescription.medicationQuantity}{" "}
                          {prescription.medicationFormulation}
                        </td>
                        <td>{prescription.received ? "Yes" : "No"}</td>
                        <td>
                          {prescription.hasUnsignedForms
                            ? "Incomplete"
                            : "Completed"}
                        </td>
                        <td>{getPrettyDate(prescription.createdAt)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </DashboardPageBody>
      {showStatusEditor &&
        createPortal(
          <Overlay>
            <Card>
              <form onSubmit={handleSubmit(_handleStatusSubmit)}>
                <div className={styles.editPatientStatusCard}>
                  <h3>Edit patient outreach status</h3>
                  <p className={styles.editPatientStatusDescription}>
                    Please select the patient's current outreach status from the
                    available options.
                  </p>
                  <div className={styles.inputContainer}>
                    <label className={styles.label} htmlFor="dispenseDate">
                      Status*
                    </label>
                    <select
                      {...register("status", {
                        required: "Status is required",
                      })}
                      className={styles.input}
                      id="patientStatus"
                      data-testid="PatientDetail__patient_status"
                    >
                      <option value={PatientStatus.UNSET}>
                        Select a status
                      </option>
                      <option value={PatientStatus.ACTIVE}>Active</option>
                      <option value={PatientStatus.INACTIVE}>
                        Lost coverage
                      </option>
                      <option value={PatientStatus.OUTREACH_IN_PROGRESS}>
                        Outreach in progress
                      </option>
                      <option value={PatientStatus.OUTREACH_DISCONTINUED}>
                        Outreach stopped
                      </option>
                      <option value={PatientStatus.DECLINED}>
                        Declined MediCircle
                      </option>
                      <option value={PatientStatus.MED_DISCONTINUED}>
                        Medication discontinued
                      </option>
                    </select>
                    {errors.status && (
                      <p className={styles.error}>{errors.status.message}</p>
                    )}
                  </div>
                  <div className={styles.inputContainer}>
                    <label className={styles.label} htmlFor="dispenseDate">
                      Status notes
                    </label>
                    <textarea
                      {...register("statusDescription")}
                      className={styles.input}
                      rows={4}
                    ></textarea>
                  </div>
                  <div className={styles.editPatientStatusActions}>
                    <SecondaryButton
                      onClick={() => setShowStatusEditor(false)}
                      type="button"
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      type="submit"
                      loading={isSubmitting}
                      testId="PatientDetail__confirm_update"
                    >
                      Update
                    </PrimaryButton>
                  </div>
                </div>
              </form>
            </Card>
          </Overlay>,
          portalContainer
        )}
    </>
  );
}
