export function sumPrices(prices: number[]) {
  return (
    Math.round(prices.reduce((a: number, b: number) => a + b, 0) * 100) / 100
  );
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function round(value: number, precision: number) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function assert<T>(value: T, message?: string): asserts value {
  if (value) {
    return;
  }

  throw new Error("Assertion failed" + (message ? `: ${message}` : ""));
}

export function getPrettyDate(date: Date) {
  var year = date.getUTCFullYear();

  var month = (1 + date.getUTCMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getUTCDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
}

export function isInternalPath(path: string) {
  return path && path.startsWith("/") && !path.startsWith("//");
}

export function getFedexTrackingUrl(trackingNumber: string) {
  return `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`;
}

export function getLocalizedTodayString() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function validateDueDate(value: string) {
  const selectedDate = new Date(value);

  const dayOfWeek = selectedDate.getUTCDay();

  if (dayOfWeek === 5 || dayOfWeek === 6 || dayOfWeek === 0) {
    return "Due date cannot be on a Friday, Saturday, or Sunday.";
  }
  return true;
}
