import styles from "./PatientDetailCard.module.css";
import { getPrettyDate } from "../../../utils";
import Card from "../../../components/Card/Card";
import { Patient, PatientListItem, PatientStatus } from "../types";
import { Link } from "react-router-dom";
import { ApiStatus } from "../../../types/api_status";
import { BeatLoader } from "react-spinners";

interface PatientDetailCardProps {
  patient: Patient | PatientListItem | null;
  status?: ApiStatus;
  linkToPatientDetail?: boolean;
  onClickStatus?: () => void;
  allowChangeStatus?: boolean;
}

export default function PatientDetailCard(props: PatientDetailCardProps) {
  return (
    <Card className={styles.patientListItem}>
      {props.patient ? (
        <div className={styles.mainContent}>
          <div className={styles.start}>
            <img
              className={styles.patientIcon}
              src={require("../../../assets/PersonGrey.svg").default}
              alt="Patient icon"
            ></img>
            <div className={styles.infoBox}>
              {props.linkToPatientDetail ? (
                <Link
                  to={`/patients/${props.patient.id}`}
                  className={`${styles.name} ${styles.link}`}
                  data-testid={`Patients__patient_link__${props.patient.id}`}
                >
                  {props.patient.fullName}
                </Link>
              ) : (
                <p className={styles.name}>{props.patient.fullName}</p>
              )}
              <p>{props.patient.displayAddress}</p>
              <div className={styles.contactBox}>
                <p className={styles.patientAttribute}>{props.patient.email}</p>
                <span className={styles.contactDivider}>●</span>
                <p className={styles.patientAttribute}>
                  {props.patient.phoneNumber}
                </p>
                <span className={styles.contactDivider}>●</span>
                <p className={styles.patientAttribute}>
                  DOB {getPrettyDate(props.patient.dateOfBirth)}
                </p>
                {props.patient.memberId && (
                  <>
                    <span className={styles.contactDivider}>●</span>
                    <p className={styles.patientAttribute}>
                      Member ID: {props.patient.memberId}
                    </p>
                  </>
                )}
                {props.patient.groupId && (
                  <>
                    <span className={styles.contactDivider}>●</span>
                    <p className={styles.patientAttribute}>
                      Group ID: {props.patient.groupId}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          {!props.allowChangeStatus ? (
            props.patient.status !== PatientStatus.UNSET ? (
              <button
                onClick={props.onClickStatus}
                disabled={true}
                className={`${styles.statusBox} ${
                  props.patient.status === PatientStatus.ACTIVE
                    ? styles.active
                    : props.patient.status ===
                      PatientStatus.OUTREACH_IN_PROGRESS
                    ? styles.pending
                    : styles.inactive
                }`}
              >
                <div className={styles.statusIndicator}></div>
                <p>{props.patient.statusDisplay}</p>
              </button>
            ) : (
              <></>
            )
          ) : props.patient.status === PatientStatus.UNSET ? (
            <button
              onClick={props.onClickStatus}
              className={styles.setStatusBtn}
            >
              Set outreach status
            </button>
          ) : (
            <button
              onClick={props.onClickStatus}
              disabled={!props.allowChangeStatus}
              className={`${styles.statusBox} ${
                props.patient.status === PatientStatus.ACTIVE
                  ? styles.active
                  : props.patient.status === PatientStatus.OUTREACH_IN_PROGRESS
                  ? styles.pending
                  : styles.inactive
              }`}
            >
              <div className={styles.statusIndicator}></div>
              <p>{props.patient.statusDisplay}</p>{" "}
            </button>
          )}
        </div>
      ) : props.status === ApiStatus.FAILURE ? (
        <p className={styles.error}>Error loading patient details</p>
      ) : (
        <div className={styles.loader}>
          <BeatLoader color={"var(--color-primary)"} size={15}></BeatLoader>
        </div>
      )}
    </Card>
  );
}
