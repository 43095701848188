import { BeatLoader } from "react-spinners";
import styles from "./styles.module.css";

interface PrimaryButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  type?: "button" | "submit" | "reset";
  loading?: boolean;
  disabled?: boolean;
  testId?: string;
}

export default function PrimaryButton(props: PrimaryButtonProps) {
  return (
    <button
      className={`${styles.button} ${styles.primary} ${
        props.loading ? styles.loading : ""
      }`}
      onClick={props.disabled ? undefined : props.onClick}
      type={props.type}
      disabled={props.disabled}
      data-testid={props.testId}
    >
      <span className={styles.content}>{props.children}</span>
      <BeatLoader
        color="white"
        className={styles.loader}
        size={11}
      ></BeatLoader>
    </button>
  );
}
