import { Dispense } from "../../../../../types/dispense";
import {
  getFedexTrackingUrl,
  getPrettyDate,
  validateDueDate,
} from "../../../../../utils";
import styles from "./DispenseListItem.module.css";
import PencilColored from "../../../../../assets/Pencil-Colored.svg";
import PencilDisabled from "../../../../../assets/Pencil-Disabled.svg";
import Trash from "../../../../../assets/Trash.svg";
import TrashDisabled from "../../../../../assets/Trash-Disabled.svg";
import { useState } from "react";
import { ApiStatus } from "../../../../../types/api_status";
import { toast } from "react-toastify";

import Overlay from "../../../../../components/Overlay/Overlay";
import Card from "../../../../../components/Card/Card";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../../../../components/Button2/PrimaryButton";
import SecondaryButton from "../../../../../components/Button2/SecondaryButton";
import { deleteDispense, editDispense } from "../../../api";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog/ConfirmationDialog";
import { createPortal } from "react-dom";

export default function DispenseListItem(props: {
  dispense: Dispense;
  prescriptionId: string;
  patientId: string;
  onDelete: () => void;
}) {
  const [dispense, setDispense] = useState(props.dispense);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ date: string }>({
    mode: "onChange",
    defaultValues: {
      date: props.dispense.dispenseDate.toISOString().substring(0, 10),
    },
  });

  const portalContainer =
    document.getElementById("portal-root") || document.body;

  const [editDispenseStatus, setEditDispenseStatus] = useState(
    ApiStatus.INITIAL
  );

  const [deleteDispenseStatus, setDeleteDispenseStatus] = useState(
    ApiStatus.INITIAL
  );

  const handleUpdate = (data: { date: string }) => {
    setEditDispenseStatus(ApiStatus.LOADING);
    setIsEditModalOpen(false);
    return editDispense(
      props.patientId,
      props.prescriptionId,
      props.dispense.pk,
      data.date
    )
      .then((dispense) => {
        setEditDispenseStatus(ApiStatus.SUCCESS);
        toast.success("Updated dispense");
        setDispense(dispense);
      })
      .catch(() => {
        setEditDispenseStatus(ApiStatus.FAILURE);
        toast.error("Error updating dispense");
      });
  };

  const handleDelete = () => {
    setDeleteDispenseStatus(ApiStatus.LOADING);
    setIsDeleteModalOpen(false);
    return deleteDispense(props.dispense.pk)
      .then(() => {
        setDeleteDispenseStatus(ApiStatus.SUCCESS);
        toast.success("Successfully deleted dispense");
        props.onDelete();
      })
      .catch((e) => {
        setDeleteDispenseStatus(ApiStatus.FAILURE);
        if (e.response?.data?.detail) {
          toast.error(`Error: ${e.response.data.detail}`);
        } else {
          toast.error("Error deleting dispense");
        }
      });
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
    reset({ date: dispense.dispenseDate.toISOString().substring(0, 10) });
  };

  return (
    <>
      <tr>
        <td>{getPrettyDate(dispense.dispenseDate)}</td>
        <td>{dispense.shippingStatus}</td>
        <td>
          {dispense.signedConsentForm ? (
            <a
              href={dispense.signedConsentForm}
              className={styles.tableLink}
              target="_blank"
              rel="noreferrer"
            >
              View
            </a>
          ) : (
            <p>Unsigned</p>
          )}
        </td>
        <td>
          {dispense.trackingNumber === "" ? (
            "N/A"
          ) : (
            <a
              href={getFedexTrackingUrl(dispense.trackingNumber)}
              className={styles.tableLink}
              target="_blank"
              rel="noreferrer"
            >
              {dispense.trackingNumber}
            </a>
          )}
        </td>
        <td>{dispense.contact || "-"}</td>
        <td>
          <div className={styles.dispenseActions}>
            {editDispenseStatus === ApiStatus.LOADING ? (
              <div className={styles.loader}></div>
            ) : (
              <button
                data-testid={`DispenseListItem__edit__${props.dispense.pk}`}
                title="Edit"
                className={styles.iconButton}
                disabled={!props.dispense.changeable}
                onClick={() => setIsEditModalOpen(true)}
              >
                <img
                  src={
                    props.dispense.changeable ? PencilColored : PencilDisabled
                  }
                  title={
                    props.dispense.changeable
                      ? "Edit dispense"
                      : "Dispense cannot be edited"
                  }
                  alt="Edit dispense"
                ></img>
              </button>
            )}
            {deleteDispenseStatus === ApiStatus.LOADING ? (
              <div className={styles.loader}></div>
            ) : (
              <button
                data-testid={`DispenseListItem__delete__${props.dispense.pk}`}
                title="Delete"
                className={styles.iconButton}
                disabled={!props.dispense.changeable}
                onClick={() => setIsDeleteModalOpen(true)}
              >
                <img
                  src={props.dispense.changeable ? Trash : TrashDisabled}
                  title={
                    props.dispense.changeable
                      ? "Delete dispense"
                      : "Dispense cannot be deleted"
                  }
                  alt="Delete dispense"
                ></img>
              </button>
            )}
          </div>
        </td>
      </tr>
      {isEditModalOpen &&
        createPortal(
          <Overlay>
            <Card>
              <form onSubmit={handleSubmit(handleUpdate)}>
                <div className={styles.editDispenseDateCard}>
                  <h3>Edit dispense date</h3>
                  <p className={styles.editDispenseDateDescription}>
                    Please note that new consent forms will have to be signed by
                    the patient.
                  </p>
                  <div className={styles.inputContainer}>
                    <label className={styles.label} htmlFor="dispenseDate">
                      Dispense date
                    </label>
                    <input
                      type="date"
                      {...register("date", {
                        required: "Due date is required",
                        validate: validateDueDate,
                      })}
                      className={styles.input}
                      id="dispenseDate"
                      data-testid="DispenseListItem__edit_dispense_input"
                    />
                    {errors.date && (
                      <p className={styles.error}>{errors.date.message}</p>
                    )}
                  </div>
                  <div className={styles.editDispenseDateActions}>
                    <SecondaryButton onClick={handleCancelEdit} type="button">
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      type="submit"
                      testId="DispenseListItem__confirm_update"
                    >
                      Update
                    </PrimaryButton>
                  </div>
                </div>
              </form>
            </Card>
          </Overlay>,
          portalContainer
        )}
      {isDeleteModalOpen &&
        createPortal(
          <ConfirmationDialog
            title="Confirm deletion"
            leftLabel="Back"
            subtitle="Are you sure you want to delete this dispense? Inventory will be unallocated."
            onCancel={() => setIsDeleteModalOpen(false)}
            isDestructive={true}
            onConfirm={handleDelete}
            confirmTestId="DispenseListItem__confirm_delete"
          />,
          portalContainer
        )}
    </>
  );
}
