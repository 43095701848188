import { Navigate, Route, Routes } from "react-router";
import Dashboard from "../../pages/Inventory/Inventory";
import Patients from "../../pages/Patients";
import ProtectedRoute from "./ProtectedRoute";
import Inventory from "../../pages/Inventory/Inventory";
import Faq from "../../pages/Faq/Faq";
import ContactSupport from "../../pages/ContactSupport/ContactSupport";
import TaskList from "../../pages/TaskList/TaskList";
import PrescriptionDetail from "../../pages/Prescriptions/PrescriptionDetail";
import AddPrescription from "../../pages/Prescriptions/PrescriptionForm";
import PatientForm from "../../pages/Patients/PatientForm";
import PatientDetail from "../../pages/Patients/PatientDetail";
import DispenseQueue from "../../pages/DispenseQueue";
import Landing from "../../pages/Landing";
import DispenseForm from "../../pages/Prescriptions/DispenseForm";
import DispenseHistory from "../../pages/DispenseHistory";
import ConfirmOrder from "../../pages/Prescriptions/ConfirmOrder";
import { Settings } from "../../pages/Settings";

export default function MyRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Landing></Landing>}></Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/inventory" element={<Dashboard />} />
        <Route path="/patients" element={<Patients />} />
        <Route path="/patients/:patientId" element={<PatientDetail />} />
        <Route path="/patient/create" element={<PatientForm />} />
        <Route path="/patient/:patientId/edit" element={<PatientForm />} />
        <Route
          path="/patients/:patientId/prescriptions/:prescriptionId"
          element={<PrescriptionDetail />}
        />
        <Route
          path="/patients/:patientId/prescriptions/create"
          element={<AddPrescription />}
        />
        <Route
          path="/patients/:patientId/prescriptions/drafts/:draftUuid/confirm"
          element={<ConfirmOrder />}
        />
        <Route
          path="/patients/:patientId/prescriptions/:prescriptionId/dispenses/new"
          element={<DispenseForm />}
        />
        <Route path="/dispenses" element={<DispenseQueue />} />
        <Route path="/order-history" element={<DispenseHistory />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/support" element={<ContactSupport />} />
        <Route path="/tasks" element={<TaskList />} />
        <Route path="/" element={<Inventory />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="*" element={<Navigate to="/inventory" />} />
      </Route>
    </Routes>
  );
}
