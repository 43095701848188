import { useState } from "react";
import styles from "./PlannedDispense.module.css";
import { ApiStatus } from "../../../../types/api_status";
import { deleteDispenseDraft, updateDispenseDraft } from "../../api";
import { DraftDispense } from "../../types";
import { toast } from "react-toastify";

import PencilColored from "../../../../assets/Pencil-Colored.svg";
import Trash from "../../../../assets/Trash.svg";
import ConfirmationDialog from "../../../../components/ConfirmationDialog/ConfirmationDialog";
import { createPortal } from "react-dom";
import DraftDispenseDateDialog from "./DraftDispenseDateDialog";

const prettyDate = (ts: Date) =>
  ts.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  });

export default function PlannedDispense(props: {
  dispense: DraftDispense;
  onUpdate: (dispense: DraftDispense) => void;
  onDeletion: () => void;
}) {
  const portalContainer =
    document.getElementById("portal-root") || document.body;

  const [updateState, setUpdateState] = useState<ApiStatus>(ApiStatus.INITIAL);
  const [deletionState, setDeletionState] = useState<ApiStatus>(
    ApiStatus.INITIAL
  );

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const _updateDispense = (date: string) => {
    setUpdateState(ApiStatus.LOADING);
    return updateDispenseDraft(props.dispense.pk, date)
      .then((dispense) => {
        toast.success("Dispense date updated");
        setUpdateState(ApiStatus.SUCCESS);
        props.onUpdate(dispense);
      })
      .catch(() => {
        toast.success("Error updating dispense date");
        setUpdateState(ApiStatus.FAILURE);
      });
  };

  const _deleteDispense = () => {
    setDeletionState(ApiStatus.LOADING);
    return deleteDispenseDraft(props.dispense.pk)
      .then(() => {
        toast.success("Successfully deleted dispense");
        setDeletionState(ApiStatus.SUCCESS);
        props.onDeletion();
      })
      .catch(() => {
        toast.success("Error deleting dispense");
        setDeletionState(ApiStatus.FAILURE);
      });
  };

  return (
    <>
      {isEditing &&
        createPortal(
          <DraftDispenseDateDialog
            isAdding={false}
            initialDate={props.dispense.dispenseDate}
            onSubmit={(date: string) => {
              setIsEditing(false);
              _updateDispense(date);
            }}
            onCancel={() => setIsEditing(false)}
          ></DraftDispenseDateDialog>,
          portalContainer
        )}
      {isDeleting &&
        createPortal(
          <ConfirmationDialog
            title={"Delete dispense"}
            subtitle="Are you sure you want to remove this dispense?"
            isDestructive
            onConfirm={() => {
              setIsDeleting(false);
              _deleteDispense();
            }}
            onCancel={() => setIsDeleting(false)}
          ></ConfirmationDialog>,
          portalContainer
        )}
      <li className={styles.stagedDispense}>
        <div className={styles.stagedDispenseContent}>
          <p className={styles.dispenseDate}>
            {prettyDate(props.dispense.dispenseDate)}
          </p>
          <div className={styles.stagedDispenseActions}>
            {updateState === ApiStatus.LOADING ? (
              <div className={styles.loader}></div>
            ) : (
              <button
                title="Edit dispense date"
                className={styles.iconButton}
                onClick={() => setIsEditing(true)}
              >
                <img
                  src={PencilColored}
                  title="Edit dispense date"
                  alt="Edit dispense date"
                ></img>
              </button>
            )}
            {deletionState === ApiStatus.LOADING ? (
              <div className={styles.loader}></div>
            ) : (
              <button
                title="Delete"
                className={styles.iconButton}
                onClick={() => setIsDeleting(true)}
              >
                <img
                  src={Trash}
                  title="Delete dispense date"
                  alt="Delete dispense date"
                ></img>
              </button>
            )}
          </div>
        </div>
      </li>
    </>
  );
}
