import { useAuth0 } from "@auth0/auth0-react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

export default function ProtectedRoute() {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate replace to={`/login${location.search}`} />;
  }

  return <Outlet />;
}
