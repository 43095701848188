import { Prescription2, PrescriptionListItem } from "../../types/prescription";
import instance from "../../api/http_client";
import { Dispense } from "../../types/dispense";
import { DraftDispense, DraftPrescription } from "./types";
import { Medication } from "../../types/medication";

export function getPrescriptions(patientId: string) {
  return instance
    .get(`/patients/${patientId}/prescriptions`)
    .then((response) => {
      return response.data.map(PrescriptionListItem.fromJson);
    });
}

/* TODO: Remove the patient requirement here */
export function getPrescription(patientId: string, prescriptionId: string) {
  return instance
    .get(`/patients/${patientId}/prescriptions/${prescriptionId}`)
    .then((response) => {
      return Prescription2.fromJson(response.data);
    });
}

export function submitPrescription(
  medicationId: string,
  patientId: string,
  daysSupply: number,
  quantity: number,
  dueDate: string,
  totalRemainingFills: number
): Promise<Prescription2> {
  return instance
    .post(`/patients/${patientId}/prescriptions/`, {
      medication_id: medicationId,
      days_supply: daysSupply,
      quantity: quantity,
      due_date: dueDate,
      total_remaining_fills: totalRemainingFills,
    })
    .then((response) => Prescription2.fromJson(response.data));
}

export function precheckPrescription(
  medicationId: string,
  patientId: string,
  daysSupply: number,
  quantity: number,
  dueDate: string,
  totalRemainingFills: number
): Promise<Date[]> {
  return instance
    .post(`/patients/${patientId}/prescriptions/precheck/`, {
      medication_id: medicationId,
      days_supply: daysSupply,
      quantity: quantity,
      due_date: dueDate,
      total_remaining_fills: totalRemainingFills,
    })
    .then((response) => response.data["fills"].map((f: string) => new Date(f)));
}

export function cancelPrescription(patientId: string, prescriptionId: string) {
  return instance.delete(
    `/patients/${patientId}/prescriptions/${prescriptionId}`
  );
}

export function editDispense(
  patientId: string,
  prescriptionId: string,
  dispenseId: string,
  dispenseDate: string
) {
  return instance
    .patch(
      `/patients/${patientId}/prescriptions/${prescriptionId}/dispenses/${dispenseId}/`,
      {
        dispense_date: dispenseDate,
      }
    )
    .then((res) => Dispense.fromJson(res.data));
}

export function addDispense(prescriptionId: string, dispenseDate: string) {
  return instance
    .post(`prescriptions/${prescriptionId}/dispenses/new`, {
      dispense_date: dispenseDate,
    })
    .then((res) => Dispense.fromJson(res.data));
}

export function deleteDispense(dispenseId: string) {
  return instance
    .delete(`dispenses/${dispenseId}/delete`)
    .then((res) => Dispense.fromJson(res.data));
}

export function createPrescriptionDraft(
  patientId: string,
  medicationId: string,
  quantity: number,
  daysSupply: number,
  remainingRefills: number,
  initialFillDate: string
): Promise<DraftPrescription> {
  return instance
    .post(`${patientId}/prescription-drafts/new`, {
      medication_id: medicationId,
      quantity: quantity,
      days_supply: daysSupply,
      total_remaining_fills: remainingRefills,
      initial_fill_date: initialFillDate,
    })
    .then((res) => DraftPrescription.fromJson(res.data));
}

export function getPrescriptionDraft(uuid: string) {
  return instance
    .get(`prescription-drafts/${uuid}`)
    .then((res) => DraftPrescription.fromJson(res.data));
}

export function updateDispenseDraft(id: string, dispenseDate: string) {
  return instance
    .patch(`dispense-drafts/${id}/update`, {
      dispense_date: dispenseDate,
    })
    .then((res) => DraftDispense.fromJson(res.data));
}

export function deleteDispenseDraft(id: string) {
  return instance.delete(`dispense-drafts/${id}/delete`);
}

export function addDispenseDraft(uuid: string, date: string) {
  return instance
    .post(`prescription-drafts/${uuid}/dispense-drafts/new`, {
      dispense_date: date,
    })
    .then((res) => DraftDispense.fromJson(res.data));
}

export function getAllocatableDispenses(
  uuid: string
): Promise<DraftDispense[]> {
  return instance
    .get(`prescription-drafts/${uuid}/allocatable-dispenses`)
    .then((res) => res.data.map(DraftDispense.fromJson));
}

export function createPrescription(draftUuid: string): Promise<Prescription2> {
  return instance
    .post(`prescriptions/from-draft/${draftUuid}`)
    .then((response) => Prescription2.fromJson(response.data));
}

export function getMedication(id: string) {
  return instance
    .get(`medications/${id}`)
    .then((res) => Medication.fromJson(res.data));
}
