import { useForm } from "react-hook-form";
import PrimaryButton from "../../components/Button2/PrimaryButton";
import Card from "../../components/Card/Card";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import { useAuth } from "../../providers/AuthUserProvider";
import styles from "./styles.module.css";
import {
  getPreferences,
  updateDispenseReminderSetting,
  updateEmailDigestSetting,
  updatePersonalInfo,
} from "./api";
import { toast } from "react-toastify";
import Switch from "react-switch";
import { useEffect, useState } from "react";
import { FetchState } from "../../types/fetch_state";
import { UserPreferences } from "./types";
import { ApiStatus } from "../../types/api_status";
import { BeatLoader } from "react-spinners";

interface PersonalInfoFormInput {
  firstName: string;
  lastName: string;
}

export function Settings() {
  const { user, updateUser } = useAuth();

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm<PersonalInfoFormInput>({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
  });

  const _submitPersonalInfoUpdate = (data: PersonalInfoFormInput) => {
    return updatePersonalInfo(data.firstName, data.lastName)
      .then((updatedUser) => {
        toast.success("Successfully updated information");
        updateUser(updatedUser);
      })
      .catch(() => toast.error("Error updating information"));
  };

  const [preferencesState, setPreferencesState] = useState<
    FetchState<UserPreferences>
  >({
    result: null,
    status: ApiStatus.INITIAL,
  });

  const _getPreferences = () => {
    setPreferencesState((prev) => ({ ...prev, status: ApiStatus.LOADING }));
    return getPreferences()
      .then((prefs) => {
        setPreferencesState({ result: prefs, status: ApiStatus.SUCCESS });
      })
      .catch(() =>
        setPreferencesState((prev) => ({ ...prev, status: ApiStatus.FAILURE }))
      );
  };

  const _updateInventoryDigestSetting = (value: boolean) => {
    if (preferencesState.result) {
      const newResult = {
        ...preferencesState.result,
        receiveInventoryDigestEmails: value,
      };
      setPreferencesState((prev) => ({ ...prev, result: newResult }));
    }
    return updateEmailDigestSetting(value).catch(() => {
      if (preferencesState.result) {
        const newResult = {
          ...preferencesState.result,
          receiveInventoryDigestEmails: !value,
        };
        setPreferencesState((prev) => ({ ...prev, result: newResult }));
      }
      toast.error("Error updating setting");
    });
  };

  const _updateDispenseReminderSetting = (value: boolean) => {
    if (preferencesState.result) {
      const newResult = {
        ...preferencesState.result,
        receiveDispenseReminderEmails: value,
      };
      setPreferencesState((prev) => ({ ...prev, result: newResult }));
    }
    return updateDispenseReminderSetting(value).catch(() => {
      if (preferencesState.result) {
        const newResult = {
          ...preferencesState.result,
          receiveDispenseReminderEmails: !value,
        };
        setPreferencesState((prev) => ({ ...prev, result: newResult }));
      }
      toast.error("Error updating setting");
    });
  };

  useEffect(() => {
    _getPreferences();
  }, []);

  useEffect(() => {
    reset({
      firstName: user?.firstName,
      lastName: user?.lastName,
    });
  }, [user?.firstName, user?.lastName, reset]);

  return (
    <DashboardPageBody title="Settings">
      {preferencesState.result ? (
        <>
          <Card className={`${styles.personalInfoWrap} ${styles.sectionCard}`}>
            <form onSubmit={handleSubmit(_submitPersonalInfoUpdate)}>
              <h2 className={styles.sectionTitle}>Personal information</h2>
              <div className={styles.nameInputs}>
                <div className={styles.inputContainer}>
                  <label className={styles.label} htmlFor="first-name">
                    First name
                  </label>
                  <input
                    className={styles.input}
                    id="first-name"
                    {...register("firstName", { required: true })}
                  ></input>
                </div>
                <div className={styles.inputContainer}>
                  <label className={styles.label} htmlFor="last-name">
                    Last name
                  </label>
                  <input
                    className={styles.input}
                    id="last-name"
                    {...register("lastName", { required: true })}
                  ></input>
                </div>
              </div>
              <div className={styles.submitWrap}>
                <PrimaryButton
                  type="submit"
                  testId="Settings__personal_info_submit"
                  disabled={!isDirty}
                  loading={isSubmitting}
                >
                  Save changes
                </PrimaryButton>
              </div>
            </form>
          </Card>

          <Card
            className={`${styles.toggleSettingsWrap} ${styles.sectionCard}`}
          >
            <h2 className={styles.sectionTitle}>Email preferences</h2>
            <div>
              <div className={styles.toggleWrap}>
                <div>
                  <p className={styles.toggleName}>
                    Receive inventory digest emails
                  </p>
                  <p className={styles.toggleDescription}>
                    Receive daily email updates on new medications added to
                    MediCircle's inventory.
                  </p>
                </div>
                <Switch
                  onChange={_updateInventoryDigestSetting}
                  checked={preferencesState.result.receiveInventoryDigestEmails}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
              <div className={styles.toggleWrap}>
                <div>
                  <p className={styles.toggleName}>
                    Receive dispense reminder emails
                  </p>
                  <p className={styles.toggleDescription}>
                    Receive emails reminding you of your organization's upcoming
                    dispenses.
                  </p>
                </div>
                <Switch
                  onChange={_updateDispenseReminderSetting}
                  checked={
                    preferencesState.result.receiveDispenseReminderEmails
                  }
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            </div>
          </Card>
        </>
      ) : (
        <BeatLoader color="var(--color-primary)"></BeatLoader>
      )}
    </DashboardPageBody>
  );
}
