import { DispenseDetail } from "../../types/dispense";
import instance from "../../api/http_client";

export function getDispenseHistory() {
  return instance
    .get("/list-dispenses")
    .then((res) => res.data.map(DispenseDetail.fromJson));
}

export function getDispenseQueue() {
  return instance
    .get("/dispense-queue")
    .then((res) => res.data.map(DispenseDetail.fromJson));
}
