import { Medication } from "../types/medication";
import instance from "./http_client";

export function getInventory(query?: string): Promise<Medication[]> {
  return instance
    .get("/medications/inventory", {
      params: query ? { search: query } : {},
    })
    .then((response) => response.data.map(Medication.fromJson));
}

export function exportAsXlsx() {
  return instance
    .get("/medications/download/xlsx", { responseType: "blob" })
    .then((res) => {
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `medicircle_inventory_${
        new Date().toISOString().split("T")[0]
      }.xlsx`;
      link.click();
      window.URL.revokeObjectURL(link.href);
    });
}

export function exportAsCsv() {
  return instance
    .get("/medications/download/csv", { responseType: "blob" })
    .then((res) => {
      const blob = new Blob([res.data], {
        type: "text/csv",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `medicircle_inventory_${
        new Date().toISOString().split("T")[0]
      }.csv`;
      link.click();
      window.URL.revokeObjectURL(link.href);
    });
}
