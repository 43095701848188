export interface DispenseJson {
  id: string;
  dispense_date: string;
  created_at: string;
  signed_consent_form?: string;
  shipping_status: string;
  tracking_number: string;
  is_changeable: boolean;
  contact: string;
}

export class Dispense {
  constructor(
    public pk: string,
    public dispenseDate: Date,
    public createdAt: Date,
    public shippingStatus: string,
    public trackingNumber: string,
    public changeable: boolean,
    public contact: string,
    public signedConsentForm?: string
  ) {}

  static fromJson(data: DispenseJson): Dispense {
    return new Dispense(
      data.id,
      new Date(data.dispense_date),
      new Date(data.created_at),
      data.shipping_status,
      data.tracking_number,
      data.is_changeable,
      data.contact,
      data.signed_consent_form
    );
  }
}

export interface DispenseDetailJson {
  id: string;
  dispense_date: string;
  created_at: string;
  signed_consent_form?: string;
  shipping_status: string;
  tracking_number: string;
  patient_id: number;
  patient_name: string;
  medication_name: string;
  medication_strength: string;
  medication_formulation: string;
  medication_quantity: number;
  prescription_id: number;
  hard_copy_received: boolean;
  total_fees: number;
  contact: string;
  days_away?: number;
}

export class DispenseDetail {
  constructor(
    public pk: string,
    public dispenseDate: Date,
    public createdAt: Date,
    public shippingStatus: string,
    public trackingNumber: string,
    public medicationName: string,
    public medicationStrength: string,
    public medicationFormulation: string,
    public medicationQuantity: number,
    public patientName: string,
    public patientId: number,
    public prescriptionId: number,
    public totalFees: number,
    public contact: string,
    public signedConsentForm?: string,
    public prescriptionTransferReceived?: boolean,
    public daysAway?: number
  ) {}

  static fromJson(data: DispenseDetailJson): DispenseDetail {
    return new DispenseDetail(
      data.id,
      new Date(data.dispense_date),
      new Date(data.created_at),
      data.shipping_status,
      data.tracking_number,
      data.medication_name,
      data.medication_strength,
      data.medication_formulation,
      data.medication_quantity,
      data.patient_name,
      data.patient_id,
      data.prescription_id,
      data.total_fees,
      data.contact,
      data.signed_consent_form,
      data.hard_copy_received,
      data.days_away
    );
  }
}
