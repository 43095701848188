import { useEffect, useState } from "react";
import { PatientListItem, PatientStatus } from "./types";
import { ApiStatus } from "../../types/api_status";
import { getPatients } from "./api";
import styles from "./styles.module.css";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import Search from "../../components/Search/Search";
import { useNavigate } from "react-router";
import PrimaryButton from "../../components/Button2/PrimaryButton";
import PatientDetailCard from "./components/PatientDetailCard";
import DashboardPageEmptyState from "../../components/DashboardPageBody/DashboardStates/DashboardPageEmptyState";
import DashboardPageFailState from "../../components/DashboardPageBody/DashboardStates/DashboardPageFailState";
import DashboardPageLoadState from "../../components/DashboardPageBody/DashboardStates/DashboardPageLoadState";
import SecondaryButton from "../../components/Button2/SecondaryButton";
import Filters from "../../assets/Filters.svg";
import { createPortal } from "react-dom";
import Overlay from "../../components/Overlay/Overlay";
import Card from "../../components/Card/Card";
import { useForm } from "react-hook-form";

interface PatientsFetchState {
  patients: PatientListItem[] | null;
  status: ApiStatus;
}

interface PatientFiltersFormData {
  outreachStatus: PatientStatus | "";
}

interface PatientFilters {
  outreachStatus: PatientStatus | null;
}

const _filterPatients = (
  query: string,
  patients: PatientListItem[],
  filters: PatientFilters
) => {
  return patients.filter((p) =>
    (p.fullName.toUpperCase().includes(query.toUpperCase()) ||
      (p.memberId?.toUpperCase().includes(query.toUpperCase()) ?? false)) &&
    filters.outreachStatus === null
      ? true
      : p.status === filters.outreachStatus
  );
};

export default function Patients() {
  const navigate = useNavigate();

  const { register, reset, handleSubmit } = useForm<PatientFiltersFormData>();

  const [patientsFilter, setPatientsFilter] = useState("");

  const [displayFilters, setDisplayFilters] = useState(false);

  const [filters, setFilters] = useState<PatientFilters>({
    outreachStatus: null,
  });

  const filterCount = Object.values(filters).filter(
    (value) => value !== null
  ).length;

  const [patientsFetchState, setPatientsFetchState] =
    useState<PatientsFetchState>({
      patients: null,
      status: ApiStatus.LOADING,
    });

  const portalContainer =
    document.getElementById("portal-root") || document.body;

  const _applyFilters = (data: PatientFiltersFormData) => {
    setDisplayFilters(false);
    setFilters({
      outreachStatus: data.outreachStatus === "" ? null : data.outreachStatus,
    });
  };

  useEffect(() => {
    getPatients()
      .then((patients) =>
        setPatientsFetchState(() => ({
          patients: patients,
          status: ApiStatus.SUCCESS,
        }))
      )
      .catch(() => {
        setPatientsFetchState((prev) => ({
          ...prev,
          status: ApiStatus.FAILURE,
        }));
      });
  }, []);

  return (
    <>
      <DashboardPageBody
        title={"Your patients"}
        headerSuffix={
          <div className={styles.suffix}>
            <Search
              placeholder="Search patients"
              onChange={(val) => setPatientsFilter(val)}
            ></Search>
            <SecondaryButton onClick={() => setDisplayFilters(true)}>
              <img
                src={Filters}
                title="Get patient form link"
                alt="Patient link"
                className={styles.filtersIcon}
              ></img>
              {`Filters${filterCount ? ` (${filterCount})` : ""}`}
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                navigate("/patient/create");
              }}
            >
              <img
                alt="Plus"
                className={styles.plusIcon}
                src={require(`../../assets/PlusWhite.svg`).default}
              ></img>
              Add patient
            </PrimaryButton>
          </div>
        }
      >
        {patientsFetchState.patients ? (
          patientsFetchState.patients.length === 0 ? (
            <DashboardPageEmptyState
              title="You don't have any patients yet"
              subtitle="Get started by clicking add patient."
            ></DashboardPageEmptyState>
          ) : _filterPatients(
              patientsFilter,
              patientsFetchState.patients,
              filters
            ).length === 0 ? (
            <DashboardPageEmptyState
              title={"No results match your query."}
            ></DashboardPageEmptyState>
          ) : (
            _filterPatients(
              patientsFilter,
              patientsFetchState.patients,
              filters
            ).map((p) => (
              <PatientDetailCard
                patient={p}
                linkToPatientDetail={true}
                allowChangeStatus={false}
              ></PatientDetailCard>
            ))
          )
        ) : patientsFetchState.status === ApiStatus.LOADING ? (
          <DashboardPageLoadState></DashboardPageLoadState>
        ) : (
          <DashboardPageFailState
            errorMessage={"Unable to load patients."}
            subtitle="Please try reloading."
          ></DashboardPageFailState>
        )}
      </DashboardPageBody>
      {displayFilters &&
        createPortal(
          <Overlay>
            <Card>
              <form onSubmit={handleSubmit(_applyFilters)}>
                <div className={styles.patientListFilterCard}>
                  <div className={styles.titleBox}>
                    <h3>Apply filters</h3>
                    <button
                      className={styles.reset}
                      onClick={() => {
                        reset({ outreachStatus: "" });
                        _applyFilters({ outreachStatus: "" });
                      }}
                    >
                      Reset all
                    </button>
                  </div>
                  <div className={styles.inputs}>
                    <label className={styles.label} htmlFor="dispenseDate">
                      Patient outreach status
                    </label>
                    <select
                      {...register("outreachStatus")}
                      className={styles.input}
                      id="patientStatus"
                      data-testid="PatientDetail__patient_status"
                    >
                      <option value="">All</option>
                      <option value={PatientStatus.ACTIVE}>Active</option>
                      <option value={PatientStatus.INACTIVE}>
                        Lost coverage
                      </option>
                      <option value={PatientStatus.OUTREACH_IN_PROGRESS}>
                        Outreach in progress
                      </option>
                      <option value={PatientStatus.OUTREACH_DISCONTINUED}>
                        Outreach stopped
                      </option>
                      <option value={PatientStatus.DECLINED}>
                        Declined MediCircle
                      </option>
                    </select>
                  </div>
                  <div className={styles.patientListFilterActions}>
                    <SecondaryButton
                      onClick={() => setDisplayFilters(false)}
                      type="button"
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton type="submit">Apply</PrimaryButton>
                  </div>
                </div>
              </form>
            </Card>
          </Overlay>,
          portalContainer
        )}
    </>
  );
}
