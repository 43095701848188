import DashboardPageBody from "../../../components/DashboardPageBody/DashboardPageBody";
import { Prescription2 } from "../../../types/prescription";

import { Link, useNavigate, useParams } from "react-router-dom";
import { addDispense, getMedication, getPrescription } from "../api";

import { BeatLoader } from "react-spinners";
import styles from "./styles.module.css";
import {
  getLocalizedTodayString,
  getPrettyDate,
  validateDueDate,
} from "../../../utils";
import { useForm } from "react-hook-form";
import PrimaryButton from "../../../components/Button2/PrimaryButton";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

import { Medication } from "../../../types/medication";

interface FormInput {
  dispenseDate: string;
}

export default function DispenseForm() {
  const { prescriptionId } = useParams();
  const { patientId } = useParams();

  const { prescription, medication } = usePrescriptionDetails(
    prescriptionId,
    patientId
  );
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormInput>();

  const _onSubmit = (data: FormInput) => {
    if (!prescriptionId) {
      return;
    }
    return addDispense(prescriptionId, data.dispenseDate)
      .then((dispense) => {
        toast.success(
          `Refill created for ${getPrettyDate(dispense.dispenseDate)}`
        );
        navigate(`/patients/${patientId}/prescriptions/${prescriptionId}`);
      })
      .catch((e) => {
        const allocationError = e.response?.data?.allocation;
        if (allocationError) {
          alert(`Error refilling prescription: ${allocationError}`);
        } else {
          alert("An error occurred");
        }
      });
  };

  return (
    <DashboardPageBody
      title="Add a dispense"
      backLink={`/patients/${patientId}/prescriptions/${prescriptionId}`}
      description={
        !prescription
          ? ""
          : `You are currently ordering a refill for ${prescription.patientName}.`
      }
    >
      {prescription && medication ? (
        <>
          <form className={styles.form} onSubmit={handleSubmit(_onSubmit)}>
            <div className={styles.pageBody}>
              <div className={styles.pageBodyHeading}>DISPENSE DETAILS</div>
              <div className={styles.inputs}>
                <div className={styles.inputContainer}>
                  <label className={styles.label}>Patient</label>
                  <div>
                    <input
                      className={styles.input}
                      value={prescription.patientName}
                      disabled
                    ></input>
                  </div>
                </div>
                <div className={styles.inputContainer}>
                  <label className={styles.label}>Medication</label>
                  <div>
                    <input
                      className={styles.input}
                      value={`${medication.displayName} ${medication.strength} ${medication.formulation} (${medication.availableStock} ${medication.formulation} available)`}
                      disabled
                    ></input>
                  </div>
                </div>
                <div className={styles.inputContainer}>
                  <label className={styles.label}>Quantity</label>
                  <input
                    className={styles.input}
                    value={`${prescription.quantity} ${prescription.medicationFormulation}`}
                    disabled
                  ></input>
                </div>
                <div className={styles.inputContainer}>
                  <label className={styles.label} htmlFor="medicationId">
                    Dispense date
                  </label>
                  <input
                    type="date"
                    data-testid="DispenseForm__dispense_date"
                    min={getLocalizedTodayString()}
                    {...register("dispenseDate", {
                      required: "Dispense date is required",
                      validate: validateDueDate,
                    })}
                    className={styles.select}
                    id="dispenseDate"
                  />
                  {errors.dispenseDate && (
                    <p className={styles.inputError}>
                      {errors.dispenseDate.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.submitWrap}>
              <Link
                className={styles.cancelButton}
                to={`/patients/${patientId}/prescriptions/${prescriptionId}`}
              >
                Cancel
              </Link>
              <PrimaryButton
                type="submit"
                loading={isSubmitting}
                testId="DispenseForm__submit"
              >
                Submit refill
              </PrimaryButton>
            </div>
          </form>
        </>
      ) : (
        <BeatLoader color="var(--color-primary)"></BeatLoader>
      )}
    </DashboardPageBody>
  );
}

function usePrescriptionDetails(prescriptionId?: string, patientId?: string) {
  const [prescription, setPrescription] = useState<Prescription2 | null>(null);
  const [medication, setMedication] = useState<Medication | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!prescriptionId || !patientId) {
      setIsLoading(false);
      return;
    }

    const fetchDetails = async () => {
      try {
        setIsLoading(true);
        const fetchedPrescription = await getPrescription(
          patientId,
          prescriptionId
        );
        setPrescription(fetchedPrescription);

        if (fetchedPrescription.medicationId) {
          const fetchedMedication = await getMedication(
            fetchedPrescription.medicationId
          );
          setMedication(fetchedMedication);
        }

        setIsLoading(false);
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error("Failed to fetch details")
        );
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, [prescriptionId, patientId]);

  return { prescription, medication, isLoading, error };
}
