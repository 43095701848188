import { useEffect, useState } from "react";
import { ApiStatus } from "../../../types/api_status";
import { Medication } from "../../../types/medication";
import { getInventory } from "../../../api/inventory_network_api";

import DashboardPageBody from "../../../components/DashboardPageBody/DashboardPageBody";
import { Controller, useForm } from "react-hook-form";
import { createPrescriptionDraft, getMedication } from "../api";
import styles from "./styles.module.css";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { getPatient } from "../../Patients/api";
import { Patient } from "../../Patients/types";
import PrimaryButton from "../../../components/Button2/PrimaryButton";
import { isInternalPath } from "../../../utils";
import AsyncSelect from "react-select/async";
import { components, InputProps } from "react-select";

import { FetchState } from "../../../types/fetch_state";
import { BeatLoader } from "react-spinners";

interface PatientFetchState {
  status: ApiStatus;
  patient: Patient | null;
}

interface FormInput {
  medicationId: string;
  daysSupply: number;
  quantity: number;
  dueDate: string;
  totalRemainingFills: number;
}

interface MedicationOption {
  value: string;
  label: string;
  subtext: string;
}

function getLocalizedTodayString() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function validateDueDate(value: string) {
  const selectedDate = new Date(value);

  const dayOfWeek = selectedDate.getUTCDay();

  if (dayOfWeek === 5 || dayOfWeek === 6 || dayOfWeek === 0) {
    return "Due date cannot be on a Friday, Saturday, or Sunday.";
  }
  return true;
}

function getOptionValueFromMed(med: Medication): MedicationOption {
  return {
    value: med.pk.toString(),
    label: `${med.brandName || med.genericName} ${med.strength} ${
      med.formulation
    }`,
    subtext: `${med.availableStock} ${med.formulation} available`,
  };
}

export default function PrescriptionForm() {
  const [initialValue, setInitialValue] = useState<
    FetchState<MedicationOption>
  >({
    result: null,
    status: ApiStatus.INITIAL,
  });

  const { patientId } = useParams();

  const [searchParams] = useSearchParams();
  const medicationId = searchParams.get("medicationId");

  let backPath = searchParams.get("back");
  if (backPath && !isInternalPath(backPath)) {
    backPath = null;
  }

  const navigate = useNavigate();

  const defaultValues: Partial<FormInput> = {
    medicationId: searchParams.get("medicationId") || "",
    daysSupply: searchParams.get("daysSupply")
      ? Number(searchParams.get("daysSupply"))
      : undefined,
    quantity: searchParams.get("quantity")
      ? Number(searchParams.get("quantity"))
      : undefined,
    dueDate: searchParams.get("dueDate") || "",
    totalRemainingFills: searchParams.get("totalRemainingFills")
      ? Number(searchParams.get("totalRemainingFills"))
      : undefined,
  };

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormInput>({ defaultValues });

  const [patientFetchState, setPatientFetchState] = useState<PatientFetchState>(
    {
      status: ApiStatus.LOADING,
      patient: null,
    }
  );

  const _submitPrescription = (data: FormInput) => {
    if (!patientId) {
      alert("Error submitting prescription");
      return;
    }

    return createPrescriptionDraft(
      patientId,
      data.medicationId,
      data.quantity,
      data.daysSupply,
      data.totalRemainingFills,
      data.dueDate
    )
      .then((res) => {
        navigate(
          `/patients/${patientId}/prescriptions/drafts/${res.uuid}/confirm`
        );
      })
      .catch((e) => {
        alert("Error submitting prescription");
      });
  };

  useEffect(() => {
    const _getPatient = () => {
      if (patientId) {
        getPatient(patientId)
          .then((patient) =>
            setPatientFetchState(() => ({
              patient: patient,
              status: ApiStatus.SUCCESS,
            }))
          )
          .catch(() => {
            setPatientFetchState((prev) => ({
              ...prev,
              status: ApiStatus.FAILURE,
            }));
          });
      }
    };
    const _loadInitialMedication = () => {
      if (medicationId) {
        setInitialValue({
          status: ApiStatus.LOADING,
          result: null,
        });
        return getMedication(medicationId)
          .then((med) => {
            setInitialValue({
              status: ApiStatus.SUCCESS,
              result: getOptionValueFromMed(med),
            });
          })
          .catch(() =>
            setInitialValue({
              status: ApiStatus.FAILURE,
              result: null,
            })
          );
      } else {
        setInitialValue({
          status: ApiStatus.SUCCESS,
          result: null,
        });
      }
    };

    _getPatient();
    _loadInitialMedication();
  }, [patientId, medicationId, setValue]);

  const _queryMedications = (query: string) => {
    return getInventory(query).then((medications) => {
      return medications.map(getOptionValueFromMed);
    });
  };

  const customOption = (props: any) => {
    const { data, innerRef, innerProps, isFocused } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={`${styles.selectOption} ${
          isFocused ? styles.focusedOption : ""
        }`}
        data-testid={`PrescriptionForm__med_result__${data.value}`}
      >
        <div className={styles.selectLabel}>{data.label}</div>
        <div className={styles.selectSubtext}>{data.subtext}</div>
      </div>
    );
  };

  const CustomInput = (props: InputProps<MedicationOption, false>) => (
    <components.Input
      {...props}
      data-testid="PrescriptionForm__medication_select"
    />
  );

  return (
    <DashboardPageBody
      title="Add prescription"
      backLink={backPath ?? `/patients/${patientId}`}
      description={`You are currently adding a prescription for ${
        patientFetchState?.patient?.fullName ?? ""
      }.`}
    >
      {initialValue.status !== ApiStatus.INITIAL &&
      initialValue.status !== ApiStatus.LOADING ? (
        <form
          onSubmit={handleSubmit(_submitPrescription)}
          className={styles.form}
        >
          <div className={styles.pageBody}>
            <div className={styles.pageBodyHeading}>PRESCRIPTION DETAILS</div>
            <div className={styles.inputs}>
              <div className={styles.inputContainer}>
                <label className={styles.label} htmlFor="medicationId">
                  Medication
                </label>
                <Controller
                  name="medicationId"
                  control={control}
                  rules={{ required: "Medication is required" }} // Validation rule
                  render={({ field }) => (
                    <AsyncSelect
                      defaultValue={initialValue.result}
                      placeholder={"Start typing a medication"}
                      onChange={(selectedOption) =>
                        setValue("medicationId", selectedOption?.value ?? "")
                      }
                      isSearchable
                      components={{ Option: customOption, Input: CustomInput }}
                      className={`${styles.select} ${styles.inputLong}`}
                      loadOptions={_queryMedications}
                      noOptionsMessage={({ inputValue }) =>
                        !inputValue
                          ? "Start typing a medication"
                          : "No results found"
                      }
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          fontSize: 15,
                          border: state.isFocused
                            ? "1px solid transparent"
                            : "1px solid #d6d6d6",
                          transition: "0",
                          outline: state.isFocused
                            ? "2px solid var(--color-primary)"
                            : "0",
                          "&:hover": {
                            border: state.isFocused
                              ? "1px solid transparent"
                              : "1px solid #d6d6d6",
                          },
                          // This line disable the blue border
                          // boxShadow: state.isFocused ? "0" : "0",
                        }),
                      }}
                    />
                  )}
                />
                {errors.medicationId && (
                  <p className={styles.error}>Medication is required</p>
                )}
              </div>
              <div className={styles.inputContainer}>
                <label className={styles.label} htmlFor="dueDate">
                  Initial fill date
                </label>
                <input
                  type="date"
                  data-testid="PrescriptionForm__due_date"
                  min={getLocalizedTodayString()}
                  {...register("dueDate", {
                    required: "Initial fill date is required",
                    validate: validateDueDate,
                  })}
                  className={`${styles.input} ${styles.inputLong}`}
                  id="dueDate"
                />
                {errors.dueDate && (
                  <p className={styles.error}>{errors.dueDate.message}</p>
                )}
              </div>
              <div className={styles.inputContainer}>
                <label className={styles.label} htmlFor="daysSupply">
                  Days supply
                </label>
                <input
                  type="number"
                  className={styles.input}
                  data-testid="PrescriptionForm__days_supply"
                  id="daysSupply"
                  {...register("daysSupply", { required: true })}
                />
                {errors.daysSupply && (
                  <p className={styles.error}>Days Supply is required</p>
                )}
              </div>
              <div className={styles.inputContainer}>
                <label className={styles.label} htmlFor="quantity">
                  Quantity
                </label>
                <input
                  className={styles.input}
                  type="number"
                  data-testid="PrescriptionForm__quantity"
                  id="quantity"
                  {...register("quantity", { required: true })}
                />
                {errors.quantity && (
                  <p className={styles.error}>Quantity is required</p>
                )}
              </div>
              <div className={styles.inputContainer}>
                <label className={styles.label} htmlFor="remainingRefills">
                  Total remaining fills
                </label>
                <input
                  className={styles.input}
                  type="number"
                  id="totalRemainingRefills"
                  max={10}
                  data-testid="PrescriptionForm__total_remaining_fills"
                  {...register("totalRemainingFills", { required: true })}
                />
                {errors.totalRemainingFills && (
                  <p className={styles.error}>
                    Total remaining fills is required
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.submitWrap}>
            <Link
              className={styles.cancelButton}
              to={backPath ?? `/patients/${patientId}`}
            >
              Cancel
            </Link>
            <PrimaryButton
              type="submit"
              loading={isSubmitting}
              testId="PrescriptionForm__submit"
            >
              Review order
            </PrimaryButton>
          </div>
        </form>
      ) : (
        <BeatLoader color="var(--color-primary)"></BeatLoader>
      )}
    </DashboardPageBody>
  );
}
