import { useEffect, useState } from "react";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import { DispenseDetail } from "../../types/dispense";
import { getDispenseHistory } from "./api";
import styles from "./styles.module.css";
import { ApiStatus } from "../../types/api_status";
import { FetchState } from "../../types/fetch_state";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Search from "../../components/Search/Search";
import Calendar from "../../assets/Calendar.svg";
import { createPortal } from "react-dom";
import Overlay from "../../components/Overlay/Overlay";
import Card from "../../components/Card/Card";
import SecondaryButton from "../../components/Button2/SecondaryButton";
import PrimaryButton from "../../components/Button2/PrimaryButton";
import { useForm } from "react-hook-form";
import {
  subMonths,
  startOfMonth,
  endOfMonth,
  subDays,
  formatISO,
  startOfYear,
} from "date-fns";
import { getFedexTrackingUrl } from "../../utils";

const getPrettyDate = (ts: Date) =>
  ts.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  });

interface DateFormData {
  startDate: string;
  endDate: string;
}

interface DateRangeState {
  startDate: Date | null;
  endDate: Date | null;
}

const _filterDispenses = (
  filter: string,
  dispenses: DispenseDetail[],
  startDate: Date | null,
  endDate: Date | null
) => {
  return dispenses.filter((d) => {
    const dispenseDate = new Date(d.dispenseDate);
    const query = filter.toUpperCase();

    return (
      (d.patientName.toUpperCase().includes(query) ||
        d.medicationName.toUpperCase().includes(query)) &&
      (startDate === null ? true : dispenseDate >= startDate) &&
      (endDate === null ? true : dispenseDate <= endDate)
    );
  });
};

export default function DispenseHistory() {
  const [dispenseHistoryState, setDispenseHistoryState] = useState<
    FetchState<DispenseDetail[]>
  >({ status: ApiStatus.INITIAL, result: null });

  const [filter, setFilter] = useState("");

  const { handleSubmit, register, reset } = useForm<DateFormData>({
    defaultValues: {
      startDate: new Date(new Date().setDate(new Date().getDate() - 30))
        .toISOString()
        .substring(0, 10),
      endDate: new Date().toISOString().substring(0, 10),
    },
  });

  const [dateRange, setDateRange] = useState<DateRangeState>({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
  });

  const [displayDateSelectionPopup, setDisplayDateSelectionPopup] =
    useState(false);

  const portalContainer =
    document.getElementById("portal-root") || document.body;

  const _getDispenseHistory = () => {
    setDispenseHistoryState((prev) => ({ ...prev, status: ApiStatus.LOADING }));
    return getDispenseHistory()
      .then((result) => {
        setDispenseHistoryState(() => ({
          result,
          status: ApiStatus.SUCCESS,
        }));
      })
      .catch(() => {
        setDispenseHistoryState((prev) => ({
          ...prev,
          status: ApiStatus.FAILURE,
        }));
      });
  };

  const _onApplyDateRange = (data: DateFormData) => {
    setDisplayDateSelectionPopup(false);

    const startDate = data.startDate === "" ? null : new Date(data.startDate);
    const endDate = data.endDate === "" ? null : new Date(data.endDate);
    setDateRange({
      startDate: startDate,
      endDate: endDate,
    });
  };

  useEffect(() => {
    _getDispenseHistory();
  }, []);

  const filteredDispenses =
    dispenseHistoryState.result &&
    _filterDispenses(
      filter,
      dispenseHistoryState.result,
      dateRange.startDate,
      dateRange.endDate
    ).sort((a, b) => b.dispenseDate.getTime() - a.dispenseDate.getTime());

  return (
    <>
      <DashboardPageBody title="Order history">
        <p className="subtitle">View and search previous orders here.</p>
        <div className={styles.searchWrap}>
          <Search
            height="40px"
            padding="0"
            width="100%"
            onChange={setFilter}
            placeholder={"Search by patient or medication name"}
          ></Search>
          <button
            className={styles.dateWrap}
            onClick={() => setDisplayDateSelectionPopup(true)}
          >
            <img
              src={Calendar}
              className={styles.calendarIcon}
              alt="Calendar"
            ></img>
            {!dateRange.startDate || !dateRange.endDate
              ? "All time"
              : `${getPrettyDate(dateRange.startDate)} — ${getPrettyDate(
                  dateRange.endDate
                )}`}
          </button>
        </div>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Patient Name</th>
                <th>Dispense Date</th>
                <th>Prescription</th>
                <th>Total fees</th>
                <th>Tracking number</th>
              </tr>
            </thead>
            <tbody>
              {filteredDispenses ? (
                filteredDispenses.length === 0 ? (
                  <tr>
                    <td colSpan={5} className={"table-empty-state"}>
                      <p>
                        {filter
                          ? `No results for ${filter}`
                          : "No dispenses available"}
                      </p>
                    </td>
                  </tr>
                ) : (
                  filteredDispenses.map((d) => (
                    <tr key={d.pk}>
                      <td>
                        <Link
                          to={`/patients/${d.patientId}?back=/order-history`}
                          className={"table-link"}
                        >
                          {d.patientName}
                        </Link>
                      </td>
                      <td>{getPrettyDate(d.dispenseDate)}</td>
                      <td>
                        <Link
                          to={`/patients/${d.patientId}/prescriptions/${d.prescriptionId}?back=/order-history`}
                          className={"table-link"}
                        >
                          {d.medicationName} {d.medicationStrength} #
                          {d.medicationQuantity}
                        </Link>
                      </td>
                      <td>${d.totalFees}</td>
                      <td>
                        {d.trackingNumber ? (
                          <a
                            href={getFedexTrackingUrl(d.trackingNumber)}
                            className={"table-link"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {d.trackingNumber}
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  ))
                )
              ) : dispenseHistoryState.status === ApiStatus.LOADING ? (
                <tr>
                  <td colSpan={5} className="table-empty-state">
                    <BeatLoader
                      color="var(--color-primary)"
                      style={{ display: "block" }}
                    ></BeatLoader>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={5} className="table-empty-state">
                    <p className="error">Error loading orders</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </DashboardPageBody>
      {displayDateSelectionPopup &&
        createPortal(
          <Overlay>
            <Card className={styles.dateSelectionCard}>
              <h3>Select date range</h3>
              {/* <p className={styles.quickOptionsTitle}>Quick options</p> */}
              <div className={styles.quickOptions}>
                <button
                  type="button"
                  onClick={() => {
                    const startDate = formatISO(
                      subDays(new Date(), 30)
                    ).substring(0, 10);
                    const endDate = formatISO(new Date()).substring(0, 10);
                    reset({
                      startDate,
                      endDate,
                    });
                    _onApplyDateRange({ startDate, endDate });
                  }}
                >
                  Last 30 days
                </button>
                <button
                  onClick={() => {
                    const startDate = formatISO(
                      startOfMonth(new Date())
                    ).substring(0, 10);
                    const endDate = formatISO(new Date()).substring(0, 10);
                    reset({
                      startDate,
                      endDate,
                    });
                    _onApplyDateRange({ startDate, endDate });
                  }}
                >
                  This month
                </button>
                <button
                  onClick={() => {
                    const startDate = formatISO(
                      startOfMonth(subMonths(new Date(), 1))
                    ).substring(0, 10);
                    const endDate = formatISO(
                      endOfMonth(subMonths(new Date(), 1))
                    ).substring(0, 10);
                    reset({
                      startDate,
                      endDate,
                    });
                    _onApplyDateRange({ startDate, endDate });
                  }}
                >
                  Last month
                </button>
                <button
                  onClick={() => {
                    const startDate = formatISO(
                      subMonths(new Date(), 6)
                    ).substring(0, 10);
                    const endDate = formatISO(new Date()).substring(0, 10);
                    reset({
                      startDate,
                      endDate,
                    });
                    _onApplyDateRange({ startDate, endDate });
                  }}
                >
                  Last 6 months
                </button>
                <button
                  onClick={() => {
                    const startDate = formatISO(
                      startOfYear(new Date())
                    ).substring(0, 10);
                    const endDate = formatISO(new Date()).substring(0, 10);
                    reset({
                      startDate,
                      endDate,
                    });
                    _onApplyDateRange({ startDate, endDate });
                  }}
                >
                  This year
                </button>
                <button
                  onClick={() => {
                    const startDate = "";
                    const endDate = "";
                    reset({
                      startDate,
                      endDate,
                    });
                    _onApplyDateRange({ startDate, endDate });
                  }}
                >
                  All time
                </button>
              </div>
              <form onSubmit={handleSubmit(_onApplyDateRange)}>
                <div className={styles.inputs}>
                  <div className={styles.inputWrap}>
                    <label className={styles.label}>Start date</label>
                    <input
                      {...register("startDate", { required: true })}
                      className={styles.input}
                      type="date"
                    ></input>
                  </div>
                  <div className={styles.inputWrap}>
                    <label className={styles.label}>End date</label>
                    <input
                      {...register("endDate", { required: true })}
                      className={styles.input}
                      type="date"
                    ></input>
                  </div>
                </div>

                <div className={styles.dateSelectionActions}>
                  <SecondaryButton
                    onClick={() => setDisplayDateSelectionPopup(false)}
                    type="button"
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    // loading={isSubmitting}
                    testId="DispenseListItem__confirm_update"
                  >
                    Apply
                  </PrimaryButton>
                </div>
              </form>
            </Card>
          </Overlay>,
          portalContainer
        )}
    </>
  );
}
