import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useSearchParams } from "react-router-dom";
import styles from "./styles.module.css";

export default function LandingPage() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  let [searchParams] = useSearchParams();

  if (isAuthenticated) {
    return <Navigate replace to="/" />;
  }

  const error = searchParams.get("error_description");
  const organization = searchParams.get("organization") || undefined;
  const invitation = searchParams.get("invitation") || undefined;

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <a href="https://medicirclerx.com">
          <img
            className={styles.logo}
            src={require("../../assets/LogoDark.svg").default}
            alt="MediCircle Logo"
          ></img>
        </a>
        <h1 className={styles.title}>
          Save on your most expensive <br></br>prescription drugs
        </h1>
        <p className={styles.subtext}>
          View our inventory of reclaimed medications.
        </p>
        {error && <p className={styles.error}>Error signing in: {error}</p>}
        <button
          data-testid="Landing__login"
          className={styles.loginButton}
          onClick={() =>
            loginWithRedirect({
              authorizationParams: {
                organization: organization,
                invitation: invitation,
              },
            })
          }
        >
          {invitation ? "Sign up" : "Sign in"}
        </button>
      </div>
    </div>
  );
}
