import { useLocation, useNavigate } from "react-router";
import styles from "./Sidebar.module.css";
import SidebarFooter from "./SidebarFooter";
import SidebarNavItem from "./SidebarNav/SidebarNavItem";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname;

  if (path === "/login" || path === "/signup" || path === "/passwordReset") {
    return null;
  }

  return (
    <div className={styles.sidebar}>
      <div>
        <img
          className={styles.logo}
          src={require("../../assets/LogoDark.svg").default}
          alt="MediCircle Logo"
        ></img>
        <div className={styles.nav}>
          <SidebarNavItem
            label="Inventory"
            onClick={() => navigate("/inventory")}
            active={path === "/inventory" || path === "/"}
          ></SidebarNavItem>{" "}
          <SidebarNavItem
            label="Patients"
            onClick={() => navigate("/patients")}
            active={path.startsWith("/patients")}
          ></SidebarNavItem>
          <SidebarNavItem
            label="Dispense queue"
            onClick={() => navigate("/dispenses")}
            active={path === "/dispenses"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="Order history"
            onClick={() => navigate("/order-history")}
            active={path === "/order-history"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="FAQ"
            onClick={() => navigate("/faq")}
            active={path === "/faq"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="Request a feature"
            onClick={() => navigate("/support")}
            active={path === "/support"}
          ></SidebarNavItem>
          <SidebarNavItem
            label="Settings"
            onClick={() => navigate("/settings")}
            active={path === "/settings"}
          ></SidebarNavItem>
        </div>
      </div>
      <SidebarFooter></SidebarFooter>
    </div>
  );
}
