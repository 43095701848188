import instance from "../../api/http_client";
import { User } from "../../types/user";
import { UserPreferences } from "./types";

export function updatePersonalInfo(firstName: string, lastName: string) {
  return instance
    .patch("users/user/update", {
      first_name: firstName,
      last_name: lastName,
    })
    .then((res) => User.fromJson(res.data));
}

export function getPreferences() {
  return instance
    .get("users/user/preferences")
    .then((res) => UserPreferences.fromJson(res.data));
}

export function updateEmailDigestSetting(receiveEmails: boolean) {
  return instance
    .patch("users/user/preferences/update", {
      receive_inventory_digest_emails: receiveEmails,
    })
    .then((res) => UserPreferences.fromJson(res.data));
}

export function updateDispenseReminderSetting(receiveEmails: boolean) {
  return instance
    .patch("users/user/preferences/update", {
      receive_dispense_reminder_emails: receiveEmails,
    })
    .then((res) => UserPreferences.fromJson(res.data));
}
