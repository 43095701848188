import { useEffect, useState } from "react";
import PrimaryButton from "../../../components/Button2/PrimaryButton";
import DashboardPageBody from "../../../components/DashboardPageBody/DashboardPageBody";

import styles from "./styles.module.css";

import { useNavigate, useParams } from "react-router-dom";
import {
  addDispenseDraft,
  createPrescription,
  getAllocatableDispenses,
  getPrescriptionDraft,
} from "../api";

import { DraftPrescription } from "../types";
import { BeatLoader } from "react-spinners";

import PlannedDispense from "./components/PlannedDispense";
import { ApiStatus } from "../../../types/api_status";
import { createPortal } from "react-dom";
import DraftDispenseDateDialog from "./components/DraftDispenseDateDialog";
import { toast } from "react-toastify";
import Overlay from "../../../components/Overlay/Overlay";
import Card from "../../../components/Card/Card";
import SecondaryButton from "../../../components/Button2/SecondaryButton";

function numberWithCommas(x: string) {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const prettyDate = (ts: Date) =>
  ts.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  });

export default function ConfirmOrder() {
  const navigate = useNavigate();
  const { draftUuid, patientId } = useParams();

  const portalContainer =
    document.getElementById("portal-root") || document.body;

  const [submissionStatus, setSubmissionStatus] = useState(ApiStatus.INITIAL);
  const [precheckStatus, setPrecheckStatus] = useState(ApiStatus.INITIAL);

  const [isAddingDispense, setIsAddingDispense] = useState(false);

  const [allocationWarning, setAllocationWarning] = useState<{
    show: boolean;
    allocatableDates: string[];
  }>({
    show: false,
    allocatableDates: ["May 3, 2025", "May 3, 2025"],
  });

  const [draftState, setDraftState] = useState<{
    draft: DraftPrescription | null;
    status: ApiStatus;
  }>({
    draft: null,
    status: ApiStatus.INITIAL,
  });

  const draft = draftState.draft;

  const _addDispense = (date: string) => {
    if (!draftUuid) {
      return;
    }
    return addDispenseDraft(draftUuid, date)
      .then((dispense) => {
        if (draftState.draft) {
          toast.success("Dispense added");
          const newDispenses = [...draftState.draft.dispenses, dispense];
          const newDraft = {
            ...draftState.draft,
            dispenses: newDispenses,
          };
          setDraftState((prev) => ({
            ...prev,
            draft: newDraft,
          }));
        }
      })
      .catch(() => {
        toast.error("Error adding dispense");
      });
  };

  const _placeOrder = () => {
    if (!draftUuid) {
      return;
    }
    setSubmissionStatus(ApiStatus.LOADING);
    return createPrescription(draftUuid)
      .then((result) => {
        setSubmissionStatus(ApiStatus.SUCCESS);
        toast.success("Prescription created");
        navigate(`/patients/${result.patientId}/prescriptions/${result.id}`);
      })
      .catch(() => {
        setSubmissionStatus(ApiStatus.FAILURE);
        toast.error("Error creating prescription");
      });
  };

  const _submitOrder = () => {
    if (!draft || !draftUuid) {
      return;
    }
    if (draft.dispenses.length === 0) {
      return alert("Please add at least one dispense");
    }
    setPrecheckStatus(ApiStatus.LOADING);
    return getAllocatableDispenses(draftUuid)
      .then((allocatableDispenses) => {
        setPrecheckStatus(ApiStatus.SUCCESS);
        if (allocatableDispenses.length === 0) {
          alert(
            "Error: We do not have enough inventory of this medication to fulfill the configured dispenses at this moment."
          );
        } else if (allocatableDispenses.length < draft.dispenses.length) {
          setAllocationWarning({
            show: true,
            allocatableDates: allocatableDispenses.map((d) =>
              prettyDate(d.dispenseDate)
            ),
          });
        } else {
          return _placeOrder();
        }
      })
      .catch(() => {
        setPrecheckStatus(ApiStatus.FAILURE);
        alert("Error creating order");
      });
  };

  useEffect(() => {
    const _getDraft = () => {
      if (!draftUuid) {
        return;
      }
      setDraftState((prev) => ({ ...prev, status: ApiStatus.LOADING }));
      getPrescriptionDraft(draftUuid)
        .then((result) => {
          setDraftState(() => ({
            draft: result,
            status: ApiStatus.SUCCESS,
          }));
        })
        .catch(() => {
          setDraftState((prev) => ({ ...prev, status: ApiStatus.FAILURE }));
        });
    };

    _getDraft();
  }, [draftUuid]);

  let backParams = undefined;

  if (draft) {
    backParams = {
      medicationId: draft.medicationId,
      daysSupply: draft.daysSupply,
      quantity: draft.quantity,
      dueDate: draft.initialFillDate.toISOString().substring(0, 10),
      totalRemainingFills: draft.totalRemainingFills,
    };
  }

  return (
    <>
      {allocationWarning.show &&
        createPortal(
          <Overlay>
            <Card className={styles.confirmDispensesCard}>
              <h3>Warning</h3>
              <p className={styles.confirmDispensesDescription}>
                MediCircle currently only has enough inventory to fulfill the
                following dispense(s):
              </p>
              <ol className={styles.confirmDispensesBox}>
                {allocationWarning.allocatableDates.map((d, i) => (
                  <li className={styles.confirmDispensesItem}>{d}</li>
                ))}
              </ol>
              <div className={styles.confirmDispensesSubmitWrap}>
                <SecondaryButton
                  onClick={() =>
                    setAllocationWarning({ show: false, allocatableDates: [] })
                  }
                  type="button"
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => {
                    setAllocationWarning({ show: false, allocatableDates: [] });
                    _placeOrder();
                  }}
                  testId="PrescriptionForm__submit"
                >
                  Proceed
                </PrimaryButton>
              </div>
            </Card>
          </Overlay>,
          portalContainer
        )}
      {isAddingDispense &&
        createPortal(
          <DraftDispenseDateDialog
            isAdding={true}
            onSubmit={(date: string) => {
              setIsAddingDispense(false);
              _addDispense(date);
            }}
            onCancel={() => setIsAddingDispense(false)}
          ></DraftDispenseDateDialog>,
          portalContainer
        )}
      <DashboardPageBody
        backLink={
          backParams
            ? `/patients/${patientId}/prescriptions/create?${new URLSearchParams(
                backParams as any
              )}`
            : `/patients/${patientId}/prescriptions/create`
        }
        title="Confirm order"
        description="Review your order below and edit the dispense dates if necessary. You can adjust these dates later if needed."
      >
        {!draft ? (
          <BeatLoader color="var(--color-primary)"></BeatLoader>
        ) : (
          <>
            <div className={`${styles.summaryCard} ${styles.orderSummaryCard}`}>
              <div className={styles.summaryCardHeading}>Order Summary</div>
              <div
                className={`${styles.summaryCardGrid} ${styles.summaryCardBody}`}
              >
                <p className={styles.summaryCardLabel}>Patient name:</p>
                <p className={styles.summaryCardValue}>{draft.patientName}</p>
                <p className={styles.summaryCardLabel}>Shipping address:</p>
                <p className={styles.summaryCardValue}>
                  {draft.patientAddress}
                </p>
                <p className={styles.summaryCardLabel}>Medication:</p>
                <p
                  className={styles.summaryCardValue}
                >{`${draft.medicationName} ${draft.medicationStrength}`}</p>
                <p className={styles.summaryCardLabel}>Quantity per fill:</p>
                <p className={styles.summaryCardValue}>
                  {draft.quantity} {draft.medicationFormulation}
                </p>
                <p className={styles.summaryCardLabel}>Fees per fill:</p>
                <p className={styles.summaryCardValue}>
                  <b>${numberWithCommas(draft.feesPerFill.toString())}</b>
                </p>
              </div>
            </div>
            <div className={styles.summaryCard}>
              <div className={styles.summaryCardHeading}>
                Planned Dispense Dates
              </div>
              <div className={styles.summaryCardBody}>
                {draft.dispenses.length > 0 && (
                  <ol className={styles.stagedDispenseList}>
                    {draft.dispenses
                      .sort(
                        (a, b) =>
                          a.dispenseDate.getTime() - b.dispenseDate.getTime()
                      )
                      .map((dispense, i) => (
                        <PlannedDispense
                          key={dispense.pk}
                          dispense={dispense}
                          onDeletion={() => {
                            const newDispenses =
                              draftState?.draft?.dispenses.filter(
                                (d) => d.pk !== dispense.pk
                              );
                            if (newDispenses && draftState.draft) {
                              const newDraft = {
                                ...draftState.draft,
                                dispenses: newDispenses,
                              };
                              setDraftState((prev) => ({
                                ...prev,
                                draft: newDraft,
                              }));
                            }
                          }}
                          onUpdate={(d) => {
                            const newDispenses = [...draft.dispenses];
                            newDispenses[i] = d;
                            if (draftState.draft) {
                              const newDraft = {
                                ...draftState.draft,
                                dispenses: newDispenses,
                              };
                              setDraftState((prev) => ({
                                ...prev,
                                draft: newDraft,
                              }));
                            }
                          }}
                        ></PlannedDispense>
                      ))}
                  </ol>
                )}
                <div>
                  <button
                    className={styles.addDispenseBtn}
                    onClick={() => setIsAddingDispense(true)}
                  >
                    + Add a dispense date
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.submitWrap}>
              <PrimaryButton
                type="submit"
                onClick={_submitOrder}
                loading={
                  precheckStatus === ApiStatus.LOADING ||
                  submissionStatus === ApiStatus.LOADING
                }
                testId="ConfirmOrder__submit"
              >
                Confirm order
              </PrimaryButton>
            </div>
          </>
        )}
      </DashboardPageBody>
    </>
  );
}
