export interface UserPreferencesJson {
  receive_inventory_digest_emails: boolean;
  receive_dispense_reminder_emails: boolean;
}

export class UserPreferences {
  constructor(
    public receiveInventoryDigestEmails: boolean,
    public receiveDispenseReminderEmails: boolean
  ) {}

  static fromJson(data: UserPreferencesJson) {
    return new UserPreferences(
      data.receive_inventory_digest_emails,
      data.receive_dispense_reminder_emails
    );
  }
}
