import { useEffect, useState } from "react";
import DashboardPageBody from "../../components/DashboardPageBody/DashboardPageBody";
import styles from "./styles.module.css";
import { DispenseDetail } from "../../types/dispense";
import { ApiStatus } from "../../types/api_status";
import { getDispenseQueue } from "./api";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { getFedexTrackingUrl } from "../../utils";
import XCircle from "../../assets/XCircle.svg";
import CheckCircle from "../../assets/CheckCircle.svg";

const prettyDate = (ts: Date) =>
  ts.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  });

export default function DispenseQueue() {
  const [dispenseQueueState, setDispenseQueueState] = useState<{
    status: ApiStatus;
    dispenses: DispenseDetail[] | null;
  }>({
    status: ApiStatus.LOADING,
    dispenses: null,
  });

  const _getDispenseQueue = () => {
    getDispenseQueue()
      .then((dispenses) => {
        setDispenseQueueState({
          dispenses: dispenses,
          status: ApiStatus.SUCCESS,
        });
      })
      .catch(() =>
        setDispenseQueueState((prev) => ({
          ...prev,
          status: ApiStatus.FAILURE,
        }))
      );
  };

  useEffect(() => {
    _getDispenseQueue();
  }, []);

  return (
    <DashboardPageBody title={"Dispense queue"}>
      <p className={styles.subtitle}>
        View and manage upcoming dispenses here.
      </p>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>Patient</th>
              <th>Dispense Date</th>
              <th>Days Away</th>
              <th>Prescription</th>
              <th>Contact</th>
              <th>Consent Forms</th>
              <th>Transfer Received</th>
              <th>Shipment Status</th>
              <th>Tracking Number</th>
            </tr>
          </thead>
          <tbody>
            {dispenseQueueState.dispenses ? (
              dispenseQueueState.dispenses.length > 0 ? (
                dispenseQueueState.dispenses.map((d, i) => (
                  <tr>
                    <td>
                      <Link
                        to={`/patients/${d.patientId}?back=/dispenses`}
                        className="table-link"
                      >
                        {d.patientName}
                      </Link>
                    </td>
                    <td>{prettyDate(d.dispenseDate)}</td>
                    <td>
                      {d.daysAway === undefined
                        ? "-"
                        : d.daysAway! < 0
                        ? `${Math.abs(d.daysAway)} days ago`
                        : `${d.daysAway} days`}
                    </td>
                    <td>
                      <Link
                        to={`/patients/${d.patientId}/prescriptions/${d.prescriptionId}?back=/dispenses`}
                        className={styles.tableLink}
                      >
                        {d.medicationName} {d.medicationStrength}
                      </Link>
                    </td>
                    <td>{d.contact || "-"}</td>
                    <td>
                      {d.signedConsentForm ? (
                        <span
                          className={`${styles.statusText} ${styles.statusTextGreen}`}
                        >
                          <img
                            src={CheckCircle}
                            alt="Check"
                            className={styles.statusIcon}
                          ></img>
                          Signed
                        </span>
                      ) : (
                        <span
                          className={`${styles.statusText} ${styles.statusTextRed}`}
                        >
                          <img
                            src={XCircle}
                            alt="X Mark"
                            className={styles.statusIcon}
                          ></img>
                          Unsigned
                        </span>
                      )}
                    </td>
                    <td>
                      {d.prescriptionTransferReceived ? (
                        <span
                          className={`${styles.statusText} ${styles.statusTextGreen}`}
                        >
                          <img
                            src={CheckCircle}
                            alt="Check"
                            className={styles.statusIcon}
                          ></img>
                          Transferred
                        </span>
                      ) : (
                        <span
                          className={`${styles.statusText} ${styles.statusTextRed}`}
                        >
                          <img
                            src={XCircle}
                            alt="X Mark"
                            className={styles.statusIcon}
                          ></img>{" "}
                          Untransferred
                        </span>
                      )}
                    </td>
                    <td>{d.shippingStatus}</td>
                    <td>
                      {d.trackingNumber === "" ? (
                        "N/A"
                      ) : (
                        <a
                          href={getFedexTrackingUrl(d.trackingNumber)}
                          className={styles.tableLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {d.trackingNumber}
                        </a>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9} className="table-empty-state">
                    No dispenses yet
                  </td>
                </tr>
              )
            ) : dispenseQueueState.status === ApiStatus.LOADING ? (
              <tr>
                <td colSpan={9} className="table-empty-state">
                  <BeatLoader
                    color="var(--color-primary)"
                    style={{ display: "block" }}
                  ></BeatLoader>
                </td>
              </tr>
            ) : (
              <td colSpan={9} className="table-empty-state">
                Error loading dispenses
              </td>
            )}
          </tbody>
        </table>
      </div>
    </DashboardPageBody>
  );
}
